import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import { Button } from '@components/Button';
import Link from '@components/Link';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import NotFound from '@components/Routing/NotFound';
import ResultsListRow from '@presenters/web/components/ClubTabs/MemberResultTable';

import { mockedDistrictAssignedCandidatesData } from '@domain/districts/constants';

import { FEATURE_LEADS_MML, isEnabled } from '@utils/features';
import getDrupalTargetOrigin from '@utils/getDrupalTargetOrigin';
import { localizedNavigate } from '@utils/localized-navigate';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  districtId: string;
}

const ManageMembershipLeadsDistrictPage: React.FC<Props> = ({
  districtId: id,
}) => {
  // total members count will be added dynamically after DIS call
  const totalCount = 20;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isLeadMMLEnabled = isEnabled(FEATURE_LEADS_MML);
  const linkForReports = `${getDrupalTargetOrigin()}${currentLanguage}/secure/application/481`;
  const PAGE_SIZE = 10;
  const CURRENT_PAGE = 1;
  const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const navigateToAddMember = () => {
    const getUrl = new URL(window.location.href);
    localizedNavigate(`${getUrl.pathname}/add-member`);
  };

  if (!isLeadMMLEnabled) {
    return <NotFound />;
  }
  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.MML-district',
          'Manage Membership Candidates'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <h2 className="relative top-8 mobile:top-1">
        {t('mml.page-district-title', 'Manage membership candidates')}
      </h2>
      <div className="flex flex-col">
        <div>
          <Link
            to={linkForReports}
            className="mt-5 block w-fit text-base text-bright-blue-600 active:text-dark-blue-400 font-sans font-medium font-lg link-styles-off"
          >
            {t(
              'mml.district-lead-report',
              'Active and Historic Membership Candidates Report'
            )}
          </Link>
        </div>
        <div>
          <Link
            to={linkForReports}
            className="mt-3 block w-fit text-base text-bright-blue-600 active:text-dark-blue-400 font-sans font-medium font-lg link-styles-off"
          >
            {t(
              'mml.district-membership-summary',
              'Membership Candidates Executive Summary'
            )}
          </Link>
        </div>
      </div>
      <div className="mb-15 mobile:mb-10">
        <Button
          secondary
          small
          className="mb-4 desktop:mb-8 mt-8"
          clickHandler={navigateToAddMember}
        >
          {t('mml.button.add-new-candidate', 'ADD NEW CANDIDATE')}
        </Button>
      </div>
      <ResultsList
        darkBackground
        headerClassName={`px-8 tablet:px-5 tablet:bg-gray-100 `}
        className="-mx-8 tablet:mx-0"
        summary={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('mml.table.candidates', 'CANDIDATES')} ({totalCount})
          </h2>
        }
      >
        {totalCount > 0 &&
          mockedDistrictAssignedCandidatesData.map(member => {
            const newMember = { ...member, id };
            return (
              <ResultsListItem key={member.id || ''}>
                <ResultsListRow {...newMember} />
              </ResultsListItem>
            );
          })}
      </ResultsList>
      {totalCount > 0 && (
        <Pagination
          pageSize={PAGE_SIZE}
          page={currentPage}
          totalCount={totalCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};
export default ManageMembershipLeadsDistrictPage;
